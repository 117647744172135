<template>
    <div>
        <CRow>
            <CCol sm="12" md="12">
            <CCard>
              <CCardHeader>
                <h4>Edit Transaction Type</h4>
              </CCardHeader>
              <CCardBody>
                <CRow>
                    <CCol col="12" class="text-left">
                       <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
                    </CCol>
                </CRow>
                <CForm>
                  <CInput
                    label="Name"
                    v-model="name"
                    maxlength = "256"
                    placeholder ="Enter Name"
                    horizontal
                  />

                  <CTextarea
                    label="Description"
                    v-model="description"
                    maxlength = "1000"
                    placeholder="Type Description..."
                    horizontal rows="3"
                  />

                  <b-row>
                    <b-col sm="3">
                        <label for="input-small">Historical Transactions Horizon (in Days)</label>
                    </b-col>
                    <b-col sm="9">
                        <b-form-input type="number" 
                        min="0" 
                        max="2147483647"
                        maxlength = "10"
                        onkeyup="if(this.value<0){this.value= this.value * -1}"                       
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" 
                        step="any" 
                        v-model="keepPastRecordDays"></b-form-input>
                    </b-col>
                  </b-row><br />

                  <b-row>
                      <b-col sm="3"></b-col>
                      <b-col sm="9">
                           <b-button-group>
                             <router-link to="/fraudtransactiontype" tag="b-button">Cancel</router-link>
                             <b-button  v-on:click="ActionUpdate()" variant="primary">Update</b-button>
                           </b-button-group>
                      </b-col>
                  </b-row><br/>
                </CForm>
               </CCardBody>
            </CCard>
          </CCol>
        </CRow>
    </div>
</template>

<script>
import FraudService from '@/api/FraudService.js';

      export default {
        data() {
          return {
              id:this.$route.params.id,
              name:'',
              description:'',
              keepPastRecordDays:0,
              seen:false,
              msg :'',
              color:''
          };
        },
        created () {
            this.getTransactionTypeById(this.id);
        },
        methods: {

         ActionUpdate(){
            let params = {}; var validate = [];
            params['name'] = this.name;
            params['description'] = this.description;
            params['keepPastRecordDays'] = this.keepPastRecordDays;

            for (var key in params) {
              if(!params[key]) { validate.push(false); } else { validate.push(true); }
            }

            params['id'] = parseInt(this.id);

            if(!validate.includes(false)){
               this.updateTransactionType(params);
            } else {
               this.msg   = 'Data Not Complete !';
               this.color = 'warning'; this.seen  = true;
               setTimeout( () =>  this.seen=false , 5000);
            }
         },

         getTransactionTypeById: function(id) {
            FraudService.getTransactionTypeById(id).then(resp => {
                this.name = resp.name;
                this.description = resp.description;
                this.keepPastRecordDays = resp.keepPastRecordDays;
            }, error => {
                console.log('error');
            });
         },

         updateTransactionType: function(params) {
            FraudService.updateTransactionType(params).then(resp => {
                  if(resp.message=='Success'){
                    this.msg   = 'Fraud Transaction Type Successfully Updated';
                    this.color = 'success';
                  } else {
                    this.msg   = !resp.data.message ? "Fraud Transaction Type Failed Updated!" : resp.data.message;
                    this.color = 'warning';
                  }
                  this.seen  = true;
                  setTimeout( () =>  this.seen=false , 5000);
               }, error => {
                console.log('error');
              });
         },

        }
      }
</script>